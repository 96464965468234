const updateForm = {
    methods: {
      async firstSaveInfo () {
        await this.$root.$confirm({
          title: '!Ups',
          message: `Primero debes guardar los cambios realizados `,
          acceptText: 'Aceptar'
        })
      },
      testChanges () {
        let test = []
        for (const i of Object.keys(this.initial)) {
          if (i === 'vouchers') {
            // For arrays and objects the comparison must be different
            test.push(JSON.stringify(this.initial[i]) === JSON.stringify(this.inscription[i]))
          } else {
            test.push(this.initial[i] === this.inscription[i])
          }
        }
        const count = test.filter(Boolean).length
        // console.log(count)
        return count
      }
    }
  }

export default updateForm
