<template>
  <v-dialog
      v-bind="$attrs"
      v-on="$listeners"
      class="container"
      width="394"
  >
    <v-card class="container__card d-flex flex-column rounded-lg" width="100%">
      <v-form @submit.prevent="matricular" ref="form">
        <div class="container__item py-11 px-10">
          <p class="container__title text-left pa-0 ma-0">Información de matricula</p>
          <p class="container__subtitle text-left pa-0 ma-0">{{ inscription.apellidos }}</p>
        </div>
        <div class="container__item px-10">
          <v-text-field
              label="Correo electronico"
              v-model="inscription.email"
              readonly
              placeholder="Nombre del código"
              dense
              outlined
              :rules="[rules.required, rules.email]"
              ref="email"
              class="pa-0 ma-0"
              :error="errorMessages.email!=''"
              :error-messages="errorMessages.email"
              @focus="errorMessages.email = ''"
          />
          <v-checkbox
              v-if="usamedicUser.first_name || usamedicUser.last_name || usamedicUser.email"
              v-model="matricula.sincronizeUser"
              :label="`Sincronizar a ${usamedicUser.first_name || usamedicUser.last_name ? (usamedicUser.first_name + ' ' + usamedicUser.last_name): usamedicUser.email}`"
              dense
              class="pa-0 ma-0 mb-2"
          ></v-checkbox>
          <v-text-field
              label="Monto pagado"
              v-model="matricula.payed"
              placeholder="Escriba el monto pagado..."
              type="number"
              dense
              outlined
              class="mb-2"
              :rules="[rules.required]"
              ref="payed"
              :error="errorMessages.monto_pagado!=''"
              :error-messages="errorMessages.monto_pagado"
              @focus="errorMessages.monto_pagado = ''"
          />
          <v-text-field
              label="Monto total"
              v-model="matricula.total"
              placeholder="Escriba el monto total..."
              dense
              outlined
              type="number"
              class="mb-2"
              :rules="[rules.required, rules.minValue(matricula.payed)]"
              ref="total"
          />
          <v-checkbox
              v-model="matricula.pagoCuotas"
              label="Pago en cuotas"
              dense
              class="pa-0 ma-0"
          ></v-checkbox>
          <v-menu
              v-model="dateMenu"
              :close-on-content-click="false"
              :nudge-right="15"
              transition="scale-transition"
              offset-y
              min-width="auto"
              dense
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-model="dateFormattedDeposito"
                  label="Fecha de depósito"
                  append-icon="mdi-calendar"
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  class="mb-2"
                  dense
                  :rules="[rules.required]"
                  ref="payed"
              ></v-text-field>
            </template>
            <v-date-picker
                v-model="matricula.depositoDate"
                @input="dateMenu = false"
            ></v-date-picker>
          </v-menu>
          <v-select
              label="Entidad financiera"
              :items="mediosPago"
              v-model="matricula.medioPago"
              item-value="id"
              item-text="nombre"
              dense
              outlined
              class="mb-2"
              :rules="[rules.required]"
              :error="errorMessages.medio_pago !== ''"
              :error-messages="errorMessages.medio_pago"
              @focus="errorMessages.medio_pago = ''"
          />
          <v-text-field
              label="Número de operación"
              v-model="matricula.numeroOperacion"
              dense
              outlined
              class="mb-2"
              :error="errorMessages.numero_operacion !== ''"
              :error-messages="errorMessages.numero_operacion"
              @focus="errorMessages.numero_operacion = ''"
          />
          <v-checkbox
              v-if="matricula.payed !== '' && matricula.total !== '' && matricula.payed !== matricula.total"
              v-model="matricula.activateAlerts"
              label="¿Deseas activar las alertas?"
              dense
              class="pa-0 ma-0"
              :class="matricula.activateAlerts ? 'mb-2': ''"
          ></v-checkbox>
          <v-menu
              v-if="matricula.activateAlerts"
              v-model="dateMenuSecond"
              :close-on-content-click="false"
              :nudge-right="15"
              transition="scale-transition"
              offset-y
              min-width="auto"
              dense
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-model="dateFormattedAlert"
                  label="Fecha de alerta de pago"
                  append-icon="mdi-calendar"
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  class="mb-2"
                  dense
                  :rules="[rules.required]"
                  ref="payed"
              ></v-text-field>
            </template>
            <v-date-picker
                v-model="matricula.alertDate"
                @input="dateMenuSecond = false"
                :min="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString()"
            ></v-date-picker>
          </v-menu>
          <!-- <v-select
            :items="period"
            item-value="value"
            item-text="name"
            outlined
            dense
            v-model="matricula.period"
            label="Repetir"
            :rules="[rules.required, rules.requiredSelect]"
            ref="period"
          >
          </v-select> -->
          <v-checkbox
              v-model="matricula.apps"
              label="Banqueo VIP"
              value="Banqueo"
              class="pa-0 ma-0"
              dense
          ></v-checkbox>
          <v-checkbox
              v-model="matricula.apps"
              label="Flashcard VIP"
              value="Flashcards"
              class="pa-0 ma-0"
              dense
          ></v-checkbox>
          <template v-if="allComplements.length">
            <div class="container__item">
              <p class="container__subtitle text-left">Complementos</p>
            </div>
            <v-checkbox
                v-for="(complement, index) in allComplements"
                :key="index"
                v-model="matricula.complements"
                :value="complement.id"
                :label="complement.nombre"
                class="pa-0 ma-0"
                dense
            ></v-checkbox>
          </template>
        </div>
        <div class="container__item py-11 px-9 d-flex justify-end">
          <v-btn @click="closeCreateModal" class="container__btn mx-1">Cerrar</v-btn>
          <v-btn type="submit" :loading="loading" class="container__btn container__btn--save mx-1">Guardar</v-btn>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios'
import {maxLength, required, requiredSelect, email, minValue, maxValue} from '@/utils/rules'
import {mapActions} from 'vuex'

export default {
  data() {
    return {
      totalFocus: true,
      dateMenu: false,
      dateMenuSecond: false,
      matricula: {
        email: '',
        payed: null,
        total: null,
        depositoDate: '',
        activateAlerts: false,
        alertDate: null,
        // period: '',
        apps: [],
        sincronizeUser: false,
        pagoCuotas: false,
        complements: []
      },
      allComplements: [],
      loading: false,
      rules: {
        required,
        requiredSelect,
        maxLength,
        email,
        minValue,
        maxValue
      },
      errorMessages: {
        email: '',
        monto_pagado: '',
        medio_pago: '',
        numero_operacion: '',
        formulario: '',
      },
      mediosPago: []
    }
  },
  props: {
    inscription: Object,
    usamedicUser: Object
  },
  computed: {
    dateFormattedDeposito() {
      return this.formatDate(this.matricula.depositoDate)
    },
    dateFormattedAlert() {
      return this.formatDate(this.matricula.alertDate)
    },
    showModalValue () {
      return this.$attrs.value
    }
  },
  watch: {
    showModalValue (newValue) {
      if (newValue) {
        this.fetchComplementsBySection(this.inscription.seccion.id)
      }
    }
  },
  methods: {
    ...mapActions(['fetchAlerts']),
    async fetchMediosPago() {
      const medioPagoResponse = await axios.get('api/admin/matriculas/medios_pago/')
      this.mediosPago = medioPagoResponse.data
    },
    async fetchComplementsBySection(sectionId) {
      const complementsResponse = await axios.get(`api/admin/secciones/${sectionId}/complementos/`)
      this.allComplements = [...complementsResponse.data]
    },
    formatDate(date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${month}/${day}/${year}`
    },
    resetFields() {
      this.matricula.email = ''
      this.matricula.payed = ''
      this.matricula.total = ''
      this.matricula.depositoDate = ''
      this.matricula.activateAlerts = false
      this.matricula.alertDate = null
      this.matricula.apps = []
      this.matricula.sincronizeUser = false
      this.$refs.form.resetValidation()
    },
    async matricular() {
      // Validate monto_pagado when pago_cuotas fiels is true
      if (this.matricula.pagoCuotas && Number(this.matricula.payed) === Number(this.matricula.total)) {
        this.errorMessages.monto_pagado = `El monto pagado (${this.matricula.payed}) no puede ser igual al total si la matricula se pagará en cuotas.`
        return
      }
      if (!this.matricula.pagoCuotas && Number(this.matricula.payed) !== Number(this.matricula.total)) {
        this.errorMessages.monto_pagado = `Si se paga al contado el monto pagado (${this.matricula.payed}) debe ser igual al monto total.`
        return
      }
      if (this.$refs.form.validate()) {
        this.loading = true
        const payload = {
          // personal information
          email: this.inscription.email,
          documento: this.inscription.documento,
          first_name: this.inscription.nombres,
          last_name: this.inscription.apellidos,
          fecha_nacimiento: this.inscription.fecha_nacimiento,
          carnet_cmp: this.inscription.carnet_cmp,
          region: this.inscription.region,
          celular: this.inscription.celular,
          institucion: this.inscription.institucion.id,
          // grados_formacion: this.inscription.grados_formacion,
          // payment information
          sincronizar_usuario: this.matricula.sincronizeUser,
          monto_pagado: this.matricula.payed,
          monto_total: this.matricula.total,
          fecha_deposito: this.matricula.depositoDate,
          alerta_activa: this.matricula.activateAlerts,
          fecha_alerta: this.matricula.alertDate,
          medio_pago: this.matricula.medioPago,
          numero_operacion: this.matricula.numeroOperacion,
          apps: this.matricula.apps,
          pago_cuotas: this.matricula.pagoCuotas,
          complementos: this.matricula.complements,
        }
        await axios.post(`api/admin/matriculas/formularios/${this.$route.params.id}/matricular/`, payload)
            .then(response => {
              this.loading = false
              if (response.status === 200) {
                this.closeCreateModal()
                this.resetFields()
                this.fetchAlerts()
                this.$root.$confirm({
                  title: 'Mensaje',
                  message: 'Matricula creada.',
                  acceptText: 'Aceptar'
                })
              }
            })
            .catch(error => {
              this.loading = false
              this.errorMessages.email = error.response.data.email || ''
              this.errorMessages.monto_pagado = error.response.data.monto_pagado || ''
              this.errorMessages.numero_operacion = error.response.data.numero_operacion || ''
              this.errorMessages.formulario = error.response.data.formulario ? error.response.data.formulario[0] : ''
              if (this.errorMessages.email) {
                this.$refs.email.$el.scrollIntoView({ block: "end", behavior: 'smooth' })
              }
              if (this.errorMessages.formulario) {
                const confirm = this.$root.$confirm({
                  title: '¡Ups!',
                  message: this.errorMessages.formulario,
                  acceptText: 'Aceptar'
                })
                if (confirm) {
                  this.resetFields()
                  this.$refs.form.resetValidation()
                  this.closeCreateModal()
                }
              }
              if (!this.errorMessages.email && !this.errorMessages.monto_pagado && !this.errorMessages.numero_operacion && !this.errorMessages.formulario) {
                this.$root.$confirm({
                  title: '¡Ups!',
                  message: "No se pudo matricular al usuario. Contacte a soporte.",
                  acceptText: 'Aceptar'
                })
              }
              if (error.response.data && error.response.data.user_seccion) {
                this.$root.$confirm({
                  title: '¡Ups!',
                  message: error.response.data.user_seccion[0],
                  acceptText: 'Aceptar'
                })
              }
            })
      }
    },
    closeCreateModal() {
      this.$emit('close')
    }
  },
  mounted() {
    this.fetchMediosPago()
    this.fetchComplementsBySection(this.inscription.seccion.id)
  }
}
</script>

<style lang="scss" scoped>
.border {
  border: 1px solid red !important;
}

.container {
  max-height: 757px !important;

  &__title {
    font-size: 17px;
    font-weight: normal;
    letter-spacing: 0px;
    color: #455A64;
    opacity: 1;
  }

  &__subtitle {
    font: normal normal 300 14px/21px Poppins;
    letter-spacing: 0px;
    color: #455A64;
    opacity: 1;
  }

  &__item {
    flex-basis: 33%;
    width: 100%;
  }

  &__btn {
    text-transform: none !important;
    background: #FFF !important;
    border: 1px solid #707070 !important;
    font-size: 16px !important;
    color: #999999;
    box-shadow: none !important;

    &--save {
      background: #009EFB !important;
      color: #FFF;
    }
  }

  &__input {
    height: 68px !important;
  }
}
</style>
